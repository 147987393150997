import { Input, Button, Box, Center, Heading, VStack, InputRightElement, InputGroup } from '@chakra-ui/react'
import React, { useRef, useEffect, useState, ReactElement } from 'react'
import useTranslation from 'next-translate/useTranslation'
import Joi from 'joi'
import { AuthProvider } from '~graphql/types'
import Layout from '~components/sielbleu/layout'
import { NextPageWithLayout } from '~pages/_app'
import { routes } from '~components/sielbleu/utils/routes'
import ActiveLink from '~ui/components/ActiveLink'
import { useRouter } from 'next/router'
import PasswordRevealIcon from '~ui/icons/PasswordRevealIcon'
import { useToggle } from 'usehooks-ts'
import Head from 'next/head'
import { identifyAnalyticsBeneficiary } from '~components/analytics/sielbleu/events'
import { openToast } from '~components/toast'
import { useNotifications } from '~components/sielbleu/contexts/NotificationContext'
import { getRedirectionUrlFromQuery, redirectToDefaultUrl } from '~components/sielbleu/utils/redirections'
import { COURS_CO } from '~components/sielbleu/utils/const'
import { usePostHog } from 'posthog-js/react'

const credentialsSchema = Joi.object({
    email: Joi.string().email({ tlds: false }).required(),
    password: Joi.string().required(),
    authProvider: Joi.string().valid(...Object.values(AuthProvider)),
})

const Page: NextPageWithLayout = () => {
    const { t } = useTranslation('sielbleu')
    const { query } = useRouter()
    const router = useRouter()
    const LOGIN_ERROR = 'login_error'
    const PASSWORD_PROVIDER = 'PASSWORD'
    const METHOD_POST = 'POST'
    const type = query?.type

    const emailInput = useRef<HTMLInputElement>(null)
    const passwordInput = useRef<HTMLInputElement>(null)
    const [error, setError] = useState<boolean>(false)
    const [isSubmitting, setSubmitting] = useState<boolean>(false)

    const posthog = usePostHog()
    useEffect(() => {
        if (error) {
            setSubmitting(false)
        }
    }, [error])

    const login = async (email: string, password: string, queryRedirect?: any) => {
        const credentials = {
            authProvider: PASSWORD_PROVIDER,
            email: email,
            password: password,
            providerToken: '',
        }

        const response = await fetch(routes.api.login, {
            method: METHOD_POST,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ credentials }),
        })

        const data = await response.json()

        if (data.success == true) {
            identifyAnalyticsBeneficiary(posthog, data.me)

            if (data.me?.groupClassUser && !data.me.prescriber?.name && type && type !== COURS_CO) {
                // need to be a complete register
                // let middleware handle the redirection
                window.location.reload()
            }

            const redirection = getRedirectionUrlFromQuery(queryRedirect)
            await redirectToDefaultUrl(
                data.me,
                router,
                redirection.pathname || undefined,
                redirection.query || undefined
            )
        } else {
            openToast({ id: LOGIN_ERROR, title: t`sielbleu.login.error` })
        }
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        setError(false)

        if (isSubmitting) {
            return
        }
        const credentials: { password: string; email: string } = {
            email: emailInput?.current?.value || '',
            password: passwordInput?.current?.value || '',
        }

        const credentialsValidation = credentialsSchema.validate(credentials)

        if (credentialsValidation.error) {
            setError(true)
            return
        }

        setSubmitting(true)

        await login(credentials.email, credentials.password, query)

        setSubmitting(false)
    }

    const [revealed, toggleReveal] = useToggle(false)

    return (
        <>
            <Head>
                <title>Siel Bleu - {t`sielbleu.login.title`}</title>
            </Head>
            <VStack as="form" onSubmit={handleSubmit}>
                <Box className="title">
                    <Heading>{t(`sielbleu.login.title`)}</Heading>
                </Box>
                <Center className="register-container">
                    <Box className="input-container">
                        <Input
                            ref={emailInput}
                            isInvalid={error}
                            autoFocus={true}
                            type="email"
                            placeholder={t`sielbleu.login.placeholder.email`}
                        />
                        <InputGroup>
                            <Input
                                ref={passwordInput}
                                isInvalid={error}
                                type={revealed ? 'text' : 'password'}
                                placeholder={t`sielbleu.login.placeholder.password`}
                            />
                            <InputRightElement>
                                <PasswordRevealIcon revealed={!revealed} onClick={toggleReveal} />
                            </InputRightElement>
                        </InputGroup>
                    </Box>
                    <ActiveLink variant="primary" href={routes.public.forgotPassword}>
                        {t(`sielbleu.forgot-password.title`)}
                    </ActiveLink>
                    <Button isLoading={isSubmitting} className="button" type="submit">
                        {t(`sielbleu.login.cta`)}
                    </Button>
                </Center>
            </VStack>
        </>
    )
}

const PageLogin = Page

PageLogin.getLayout = function getLayout(page: ReactElement) {
    return <Layout>{page}</Layout>
}

export default PageLogin
