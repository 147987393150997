import {
    EVENT_ACCOUNT_REGISTER_STARTED,
    EVENT_LIVE_SESSION_CONNECTED,
    EVENT_WORKOUT_QUIT,
    EVENT_WORKOUT_STARTED,
} from '~components/analytics/sielbleu/const'

export const identifyAnalyticsBeneficiary = (posthog, user) => {
    posthog.identify(user.postHogUuid)
}

export const loggedOutAnalyticsEvent = (posthog) => {
    posthog.reset(true)
}

export const accountRegisterStarted = (posthog, properties) => {
    posthog?.capture(EVENT_ACCOUNT_REGISTER_STARTED, properties)
}

export const workoutStartedAnalyticsEvent = (posthog, properties) => {
    posthog?.capture(EVENT_WORKOUT_STARTED, properties)
}

export const workoutQuitAnalyticsEvent = (posthog, properties) => {
    posthog?.capture(EVENT_WORKOUT_QUIT, properties)
}

export const liveSessionConnectedAnalyticsEvent = (posthog, properties) => {
    posthog?.capture(EVENT_LIVE_SESSION_CONNECTED, properties)
}
