// Event name
export const EVENT_WORKOUT_STARTED = 'workout started'
export const EVENT_WORKOUT_QUIT = 'workout quit'
export const EVENT_LIVE_SESSION_CONNECTED = 'live session connected'
export const EVENT_ACCOUNT_REGISTER_STARTED = 'account register started'

// Event property
export const PROPERTY_WORKOUT_TYPE = 'workout type'
export const PROPERTY_WORKOUT_THEME = 'workout theme'
export const PROPERTY_SESSION_ID = 'session id'
export const PROPERTY_SESSION_NAME = 'session name'
export const PROPERTY_SESSION_IS_SEATED = 'session is seated'
export const PROPERTY_PRESCRIBER = 'prescriber'
export const PROPERTY_GROUP_CLASS = 'cours co'

// Property Value
export const PROPERTY_VIDEO = 'video'
